import { IconProps } from '~/components/icons/icon';

export function OwlIcon({ width = 24, height = 24 }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7128_2229)">
        <path
          d="M29.7344 33.5156C28.1557 33.5156 26.6103 33.2564 25.1457 32.7553C24.4224 33.7735 23.2634 34.375 22 34.375C20.737 34.375 19.5781 33.7737 18.8543 32.7553C17.3897 33.2564 15.8443 33.5156 14.2656 33.5156C9.47504 33.5156 5.2323 31.1273 2.66406 27.4792V34.1172C2.66406 34.8145 3.21853 35.3854 3.91548 35.4057C7.29506 35.5042 10.6756 36.3493 13.6915 37.8496C16.5015 39.2472 18.9681 41.1659 21.0231 43.5521C21.2681 43.8365 21.6246 44 21.9999 44C22.3752 44 22.7318 43.8365 22.9767 43.5521C25.0317 41.1658 27.4984 39.2472 30.3083 37.8496C33.3242 36.3493 36.7048 35.5043 40.0843 35.4057C40.7815 35.3854 41.3359 34.8145 41.3359 34.1172V27.4792C38.7677 31.1273 34.525 33.5156 29.7344 33.5156Z"
          fill="#0D0F25"
        />
        <path
          d="M25.2931 19.1122L24.6279 20.0433C24.9739 22.5528 27.1314 24.4922 29.7345 24.4922C32.5777 24.4922 34.8908 22.1791 34.8908 19.3359C34.8908 17.08 33.4339 15.1591 31.412 14.4613C28.9605 15.3543 26.8226 16.9708 25.2931 19.1122ZM28.4454 18.4766C28.4454 17.7647 29.0226 17.1875 29.7345 17.1875C30.4464 17.1875 31.0236 17.7647 31.0236 18.4766V20.1953C31.0236 20.9072 30.4464 21.4844 29.7345 21.4844C29.0226 21.4844 28.4454 20.9072 28.4454 20.1953V18.4766Z"
          fill="#0D0F25"
        />
        <path
          d="M34.7417 13.4468C36.4093 14.8665 37.4691 16.9794 37.4691 19.3359C37.4691 23.6007 33.9995 27.0703 29.7348 27.0703C29.564 27.0703 29.3947 27.0641 29.2267 27.0532C28.6484 28.3449 27.8634 29.5443 26.9102 30.5894C27.826 30.8184 28.7726 30.9375 29.7348 30.9375C36.1319 30.9375 41.3363 25.733 41.3363 19.3359C41.3363 17.4952 40.894 15.6623 40.057 14.0355C39.606 13.1588 39.0394 12.3392 38.3799 11.6021L37.1788 12.2955C36.398 12.7463 35.583 13.1309 34.7417 13.4468Z"
          fill="#0D0F25"
        />
        <path
          d="M5.6204 11.6023C4.96091 12.3393 4.39441 13.159 3.94333 14.0357C3.10638 15.6624 2.66406 17.4953 2.66406 19.3361C2.66406 25.7332 7.86852 30.9376 14.2656 30.9376C15.2278 30.9376 16.1744 30.8185 17.0902 30.5895C16.1369 29.5443 15.3519 28.3448 14.7737 27.0533C14.6057 27.0643 14.4364 27.0704 14.2656 27.0704C10.0009 27.0704 6.53125 23.6008 6.53125 19.3361C6.53125 16.9796 7.59112 14.8666 9.25865 13.4469C8.41741 13.131 7.60246 12.7466 6.82155 12.2957L5.6204 11.6023Z"
          fill="#0D0F25"
        />
        <path
          d="M12.5881 14.4613C10.5663 15.1591 9.10938 17.08 9.10938 19.3359C9.10938 22.1791 11.4225 24.4922 14.2656 24.4922C16.8688 24.4922 19.0261 22.5528 19.3721 20.0433L18.707 19.1122C17.1775 16.9708 15.0397 15.3543 12.5881 14.4613ZM15.5547 20.1953C15.5547 20.9072 14.9775 21.4844 14.2656 21.4844C13.5537 21.4844 12.9766 20.9072 12.9766 20.1953V18.4766C12.9766 17.7647 13.5537 17.1875 14.2656 17.1875C14.9775 17.1875 15.5547 17.7647 15.5547 18.4766V20.1953Z"
          fill="#0D0F25"
        />
        <path
          d="M21.8336 20.9311C21.3123 23.4071 19.604 25.4508 17.333 26.4356C17.8452 27.4555 18.5201 28.3942 19.3265 29.2006C19.9274 29.8014 20.4602 30.4719 20.9102 31.1933C21.1458 31.5712 21.5534 31.7969 22.0004 31.7969C22.4475 31.7969 22.8551 31.5712 23.0907 31.1933C23.5407 30.4719 24.0736 29.8015 24.6745 29.2006C25.4809 28.3941 26.1557 27.4555 26.668 26.4356C24.397 25.4508 22.6887 23.407 22.1673 20.9311C22.1118 20.9335 22.0564 20.9362 22.0005 20.9362C21.9447 20.9362 21.8892 20.9334 21.8336 20.9311Z"
          fill="#0D0F25"
        />
        <path
          d="M42.0639 0.167674L37.4692 2.82039C35.3319 4.05437 32.7256 4.10464 30.5156 3.00619C27.9501 1.73096 25.0596 1.01253 22 1.01253C18.9425 1.01253 16.0588 1.73603 13.4977 3.01324C11.2911 4.11358 8.68688 4.0652 6.5515 2.83234L1.93606 0.167674C1.07763 -0.327928 0.0225725 0.339549 0.0889163 1.3286C0.264573 3.95021 1.72568 6.37657 4.08028 7.73602L8.11058 10.0629C9.25982 10.7264 10.4986 11.2211 11.7888 11.5319C15.428 12.4084 18.6292 14.5676 20.8049 17.6137L20.951 17.8182C21.4651 18.5379 22.5348 18.5379 23.0489 17.8182L23.195 17.6137C25.3708 14.5676 28.572 12.4083 32.2112 11.5319C33.5013 11.2211 34.7401 10.7265 35.8894 10.0629L39.9197 7.73602C42.2743 6.37657 43.7354 3.9503 43.911 1.3286C43.9774 0.339549 42.9223 -0.327928 42.0639 0.167674Z"
          fill="#0D0F25"
        />
      </g>
      <defs>
        <clipPath id="clip0_7128_2229">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
